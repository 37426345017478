(function($) {
	$.fn.selector = function() {
		var $selects = $(this);

		var counter = 99;
		$selects.each(function(index, select) {
			var $select = $(select);
			var $selector = $("<div>").addClass('selector').css("z-index", counter--);

			if ($select.data("theme") == 2)
				$selector.addClass('theme2');

			$selector.append($("<div>").addClass('selector-header'));
			$selector.append($("<ul>").addClass('selector-items'));

			$select.find("option").each(function(index, el) {
				var $item = $("<li>").addClass('item').text($(el).text());
				if ($(el).attr("value")) 
					$item.attr("data-value", $(el).attr("value"));
				else
					$item.attr("data-value", $(el).text());
				$selector.find(".selector-items").append($item);		
			});

			if ($select.find("option:selected").text())
				$selector.find(".selector-header").text($select.find("option:selected").text());

			$selector.find(".item[data-value=" + $select.val() + "]").addClass('active');


			$selector.find(".selector-header").on("click", function() {
				$selector.toggleClass('opened');
				if ($select.data("theme") == 2)
					$selector.find(".selector-items").slideToggle(400);
				else
					$selector.find(".selector-items").fadeToggle(400);
			});

			$selector.find(".item").on("click", function() {
				var $item = $(this);
				$item.addClass('active').siblings().removeClass("active");
				$selector.find(".selector-header").text($item.text());
				$selector.toggleClass('opened').find(".selector-items").fadeToggle(400);

				$selector.prev("select").val($item.data("value"));
				$selector.prev("select").trigger("change");
			});

			$select.on("change", function() {
				var $target = $select.next(".selector").find(".item[data-value="  + $select.val() + "]");
				$target.addClass('active').siblings().removeClass('active');
				$target.parents(".selector-items").prev(".selector-header").text($target.text());
			});

			$selector.insertAfter($select);
		});

		return $selects;
	}

	$(document).on("click", function(e) {
		if ($(e.target).parents(".selector").length <= 0)
			$(".selector").removeClass('opened').find(".selector-items").fadeOut(400);
	});

	$.fn.radio = function() {
		var $radios = $(this);

		$radios.each(function(i, radio) {
			var $radio = $(radio);
			if (!$radio.hasClass('generated')) {
				var innerVal = $radio.html();
				var id = $radio.data('id');
				var name = $radio.data('name');
				var classes = $radio.data('class');
				var value = $radio.data('value');

				if (!id) {
					id = '__' + parseInt(Math.random() * 10000);
					while($(id).length > 0) { id = '_' + parseInt(Math.random() * 10000); }
				}

				if (!name) {
					name = $radio.parents('.radios-group').find('.radio input').attr('name');
					if (!name) {
						name = '__' + parseInt(Math.random() * 10000);
						while($('[name=' + name + ']').length > 0) { name = '__' + parseInt(Math.random() * 10000); }
					}
				}

				$radio.empty();
				$radio.append($('<input>').addClass(classes).attr('type', 'radio').attr('id', id).attr('name', name).attr('value', value));
				$radio.append($('<label>').attr('for', id).addClass('icon'));
				$radio.append($('<label>').attr('for', id).addClass('text').html(innerVal));
				$radio.addClass('generated');
			}
		});

		return $radios;
	}

	$.fn.checkbox = function() {
		var $checkboxs = $(this);

		$checkboxs.each(function(i, checkbox) {
			var $checkbox = $(checkbox);
			if (!$checkbox.hasClass('generated')) {
				var innerVal = $checkbox.html();
				var id = $checkbox.data('id');
				var name = $checkbox.data('name');
				var classes = $checkbox.data('class');
				var value = $checkbox.data('value');

				if (!id) {
					id = '__' + parseInt(Math.random() * 10000);
					while($(id).length > 0) { id = '_' + parseInt(Math.random() * 10000); }
				}

				if (!name) {
					name = '__' + parseInt(Math.random() * 10000);
					while($('[name=' + name + ']').length > 0) { name = '__' + parseInt(Math.random() * 10000); }
				}

				$checkbox.empty();
				$checkbox.append($('<input>').addClass(classes).attr('type', 'checkbox').attr('id', id).attr('name', name).attr('value', value));
				$checkbox.append($('<label>').attr('for', id).addClass('icon'));
				$checkbox.append($('<label>').attr('for', id).addClass('text').html(innerVal));

				if ($checkbox.data('checked')) {
					$checkbox.find('input[type=checkbox]').prop('checked', true);
				}

				$checkbox.addClass('generated');
			}
		});
		return $checkboxs;
	}

	$.fn.sameHeight = function(selectors, max) {
		var $elems = $(this);

		$(window).on("resize", function() {
			if (!max)
				max = 0;
			if ($(window).innerWidth() >= max) {
				var maxHeight = 0;
				$elems.each(function(index, el) {
					var height = 0;

					if (selectors) {
						for(i in selectors) {
							if ($(el).find(selectors[i]).length > 0)
								height += $(el).find(selectors[i]).outerHeight(true);
						}
						height += parseInt($(el).css("padding-top"));
						height += parseInt($(el).css("padding-bottom"));
					}
					else {
						$(el).css("height", "auto");
						height += $(el).outerHeight();
					}

					if (height > maxHeight)
						maxHeight = height;
				});

				$elems.css("height", maxHeight);
			}
			else {
				$elems.css("height", "auto");
			}
		});

		$(window).trigger("resize");
		return $elems;	
	}

	$.fn.textCap = function(text) {
		var $elems = $(this);
		text = text.toUpperCase().substring(0, 1) + text.toLowerCase().substring(1);
		$elems.text(text);
		return $elems;
	}

	$.fn.validateForm = function() {
		var $forms = $(this);

		$forms.find(".input-validation-container input, .input-validation-container textarea").on("input blur", function() {
			var $this = $(this);
			$this.removeClass('error');
			if ($this.siblings(".validation").is(":visible")) {
				$this.siblings(".validation").text("").fadeOut(200);
			}
		});

		var counter = 0;
		var form = $forms.get(0);
		$(form).find(".input-validation-container .validation").text("").fadeOut(200);
		$(form).find(".input-validation-container").each(function(index, el) {
			var $input = $(el).find("input, textarea");
			var $validator = $(el).find(".validation");

			if ($validator.data("required")) {
				if (/*["text", "number", "email", "password"].indexOf($input.attr("type")) != -1 && */$input.val().trim() == "") {
					$validator.text($validator.data("required"));
					$validator.fadeIn(200);
					$input.addClass('error').focus();
					counter++;
					return false;
				}
			}

			if ($validator.data("email")) {
				var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (!pattern.test($input.val())) {
					$validator.text($validator.data("email"));
					$validator.fadeIn(200);
					$input.addClass('error').focus();
					counter++;
					return false;
				}
			}
		});	

		return counter <= 0;
	}

	renderDate = function(date) {
		var dateArray = date.split(".");
		if (dateArray.length == 3) {
			var d = dateArray[0], m = dateArray[1], y = dateArray[2];
			var generatedDate = "";
			
			generatedDate += (d.length == 1 ? "0" + d : d) + ".";
			generatedDate += (m.length == 1 ? "0" + m : m) + ".";
			generatedDate += y.length != 4 ? "20" + y : y;
			
			return generatedDate;
		}
		else
			return date;
	}

	$(".text-capetalize").each(function(index, el) {
		var text = $(el).text();
		text = text.toUpperCase().substring(0, 1) + text.toLowerCase().substring(1);
		$(el).text(text);
	});

	$(document).on("mouseenter click", ".gsr-tooltip-container", function() {
		var $this = $(this);

		if ($this.is(".follow")) {
			if ($this.hasClass('following')) {
				$this.find(".gsr-tooltip").html("Pour vous désabonner de cette fiche, cliquer <span>ici</span>");
			}
			else {
				if ($("body").hasClass('known')) {
					$this.find(".gsr-tooltip").html("S'abonner pour recevoir les mises à jour de cette fiche par e-email");
				}
				else {
					if (!$this.find(".gsr-tooltip").is(":visible")) {
						$this.find(".gsr-tooltip")
											.replaceWith($("<a>")
											.addClass($this.find(".gsr-tooltip").get(0).className + " login-lightbox-loader login")
											.attr("href", "#login-lightbox")
											.html("<div class='btn-close'></div>Pour recevoir les mises à jour de cette fiche par e-email, veuillez vous connecter au créer un compte <span>ici</span>"));

						$(".login-lightbox-loader").fancybox({
							touch: false,
					        beforeLoad: function (lightbox) {
					            if ($(lightbox.current.opts.$orig[0]).hasClass('login'))
					                $("#login-lightbox").addClass('login');
					            else
					                $("#login-lightbox").removeClass('login');

								var $visibleBlocks = $("#login-lightbox .block").filter(function(i, item) { return $(item).css("display") !== "none" });
								$visibleBlocks.eq(0).css("min-height", $(window).innerHeight() - ($visibleBlocks.eq(1).find(".title").outerHeight() || 80));
								$visibleBlocks.eq(1).css("min-height", $(window).innerHeight() - ($visibleBlocks.eq(0).find(".title").outerHeight() || 80));
					        },
					        afterShow: function() {
					        	resetLightbox();
					        }
						});
					}
				}
			}
		}

		if (Modernizr.touch) {
			if (!$("body").hasClass('known')) {
				$this.find(".gsr-tooltip").addClass('mobile').fadeIn(400);
			}
		}
		else {
			$this.find(".gsr-tooltip").fadeIn(400);
		}

		if ($(window).innerWidth() <= 480) {
			$(".gsr-tooltip").offset({ left: 0 });
		}

		$(".gsr-tooltip .btn-close").one("click", function() {
			$(this).parent().fadeOut(400);
			return false;
		});
	});

	$(document).on("mouseleave", ".gsr-tooltip-container", function() {
		var $this = $(this);
		setTimeout(function() {
			if (!$this.is(":hover") && !$this.find(".gsr-tooltip").is(":hover"))
				$this.find(".gsr-tooltip").fadeOut(400);
		}, 300);
	});

	$(".gsr-tooltip-container").on("click", ".gsr-tooltip", function() {
		var $this = $(this);
		$this.fadeOut(400);
	});


	$(document).on("click", ".follow", function() {
		var $this = $(this);

		if ($("body").hasClass('known')) {
			var file = $this.data("id") || $this.parents(".block").data("id");
			if (file) {
				$.ajax({
					url: "index.php?option=com_guide&task=subscriber.addfavoris",
					type: "GET",
					data: { file: file }
				});

				if ($this.hasClass('following')) {
					$this.removeClass('following').addClass('not-following');
					//if ($this.find(".text").length > 0)
					$this.find(".gsr-tooltip").text("S'abonner pour recevoir les mises à jour de cette fiche par e-email");
				}
				else {
					$this.removeClass('not-following').addClass('following');
					//if ($this.find(".text").length > 0)
					$this.find(".gsr-tooltip").html("Pour vous désabonner de cette fiche, cliquer <span>ici</span>");
				}
			}
		}
		else {
			if (!Modernizr.touch)
				$this.find(".gsr-tooltip").trigger("click");
		}
	});

	var lastWindowWidth = null;
	$(window).on("resize", function() {
		if ($(window).innerWidth() >= 992)
			$(".wrapper").css("padding-top", $("#nav .main-items").outerHeight() + $("#nav .search").outerHeight());
		else
			$(".wrapper").css("padding-top", $("#nav .main-items").outerHeight());
		
		$(".all-content").css("min-height", window.innerHeight - $("#nav").outerHeight() - $("#footer").outerHeight());

		if (this.parallaxTimeout)
		clearTimeout(this.parallaxTimeout);
		
		this.parallaxTimeout = setTimeout(function() {
			if ($(window).innerWidth() != lastWindowWidth) {
				$(window).trigger("resize");
				lastWindowWidth = $(window).innerWidth();
			}
	    }, 1000);
	});

	$(window).trigger("resize");

})(jQuery)